import { SECOND } from '$lib/data/time';
import type { Writable } from 'svelte/store';
export const untilWritableStateRejectionErrorMessage = 'Timeout waiting for writable state';

/**
 * Waits until the writable store is in the desired state.
 * @param writableInstance The writable store to watch.
 * @param desiredState The state to wait for.
 * @returns A promise that resolves when the writable store is in the desired state.
 */
export async function untilWritableState<T>(writableInstance: Writable<T>, desiredState: T) {
	let resolveWritableStatePromise: (dataSource: T) => void;
	let rejectWritableStatePromise: (error: Error) => void;

	const rejectTimeout = setTimeout(() => {
		rejectWritableStatePromise(new Error(untilWritableStateRejectionErrorMessage));
	}, SECOND * 5);

	const writableStatePromise = new Promise<T>((resolve, reject) => {
		resolveWritableStatePromise = resolve;
		rejectWritableStatePromise = reject;
	});

	const unsubscribe = writableInstance.subscribe((state) => {
		if (state === desiredState) {
			clearTimeout(rejectTimeout);
			resolveWritableStatePromise(state);
		}
	});

	return writableStatePromise.finally(() => {
		unsubscribe();
	});
}
